import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col, FormSelect } from 'shards-react';
import ResumeItemCard from "./ResumeItemCard";
import ResumeItem from "./models/ResumeItem";
import { ScaleLoader } from "react-spinners";
import { FirebaseApp } from 'firebase/app';
import { getFirestore, collection, getDocs, query } from 'firebase/firestore';

// Cache object to store collections by path
const collectionsCache = new Map<string, Array<ResumeItem>>();

interface ResumeViewComponentProps {
    app: FirebaseApp;
    path: string;
}

const ResumeViewComponent: React.FC<ResumeViewComponentProps> = ({ app, path }) => {
    const [resumeItems, setResumeItems] = useState<Array<ResumeItem>>([]);
    const [filteredItems, setFilteredItems] = useState<Array<ResumeItem>>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sortBy, setSortBy] = useState('year');
    const [sortOrder, setSortOrder] = useState('desc');
    const [filterTag, setFilterTag] = useState('all');
    
    // Get unique tags from all resume items
    const getAllTags = (items: ResumeItem[]) => {
        const tags = new Set<string>();
        items.forEach(item => {
            item.tags?.forEach(tag => tags.add(tag));
        });
        return Array.from(tags);
    };

    const getCollection = async () => {
        // Check cache first
        if (collectionsCache.has(path)) {
            const cachedItems = collectionsCache.get(path) || [];
            setResumeItems(cachedItems);
            setFilteredItems(cachedItems);
            setIsLoading(false);
            return;
        }

        // If not in cache, fetch from Firebase
        const db = getFirestore(app);
        const q = query(collection(db, path));
        const querySnapshot = await getDocs(q);
        
        const items: Array<ResumeItem> = [];
        querySnapshot.forEach((doc) => {
            items.push(doc.data() as ResumeItem);
        });

        // Store in cache
        collectionsCache.set(path, items);
        
        setResumeItems(items);
        setFilteredItems(items);
        setIsLoading(false);
    };

    const filterAndSortItems = () => {
        let filtered = [...resumeItems];

        // Apply tag filter
        if (filterTag !== 'all') {
            filtered = filtered.filter(item => item.tags?.includes(filterTag));
        }

        // Apply sorting
        filtered.sort((a, b) => {
            let comparison = 0;
            switch (sortBy) {
                case 'year':
                    comparison = a.year - b.year;
                    break;
                case 'title':
                    comparison = a.title.localeCompare(b.title);
                    break;
                case 'semester':
                    comparison = a.semester.localeCompare(b.semester);
                    break;
            }
            return sortOrder === 'desc' ? -comparison : comparison;
        });

        setFilteredItems(filtered);
    };

    useEffect(() => {
        getCollection();
    }, [path]);

    useEffect(() => {
        filterAndSortItems();
    }, [sortBy, sortOrder, filterTag, resumeItems]);

    if (isLoading) {
        return (
            <ScaleLoader 
                height={50}
                color={"blue"}
                css={"margin: auto; padding-top: 25%"}
            />
        );
    }

    return (
        <div>
            <Container>
                <Row className="mb-4">
                    <Col sm="12" lg="4">
                        <FormSelect 
                            value={filterTag}
                            onChange={e => setFilterTag(e.target.value)}
                        >
                            <option value="all">All Tags</option>
                            {getAllTags(resumeItems).map(tag => (
                                <option key={tag} value={tag}>{tag}</option>
                            ))}
                        </FormSelect>
                    </Col>
                    <Col sm="12" lg="4">
                        <FormSelect
                            value={sortBy}
                            onChange={e => setSortBy(e.target.value)}
                        >
                            <option value="year">Year</option>
                            <option value="title">Title</option>
                            <option value="semester">Semester</option>
                        </FormSelect>
                    </Col>
                    <Col sm="12" lg="4">
                        <FormSelect
                            value={sortOrder}
                            onChange={e => setSortOrder(e.target.value)}
                        >
                            <option value="desc">Descending</option>
                            <option value="asc">Ascending</option>
                        </FormSelect>
                    </Col>
                </Row>
                <Row noGutters={true} className={'rowContainer'}>
                    {filteredItems.map((resumeItem: ResumeItem) => (
                        <Col key={resumeItem.title} sm="12" lg="4">
                            <ResumeItemCard {...resumeItem} />
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default ResumeViewComponent;